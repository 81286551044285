import { ImageModel } from './ImageModel';

export enum PriceOfferCustomerResponse {
    ACCEPTED = 'Accepted',
    DECLINED = 'Declined',
    EXPIRED = 'Expired',
    RENEWOFFER = 'RenewOffer',
    DECLINED_PRICE_TOO_LOW = 'DeclinedPriceTooLow',
    DECLINED_VALUE_EXPLORATION = 'DeclinedValueExploration',
    DECLINED_SELLING_TO_SOMEONE_ELSE = 'DeclinedSellingToSomeoneElse',
    DECLINED_OTHER = 'DeclinedOther',
}

export interface OfferModel {
    id: string;
    purchasePrice: number;
    justification: string;
    offeredAt?: string;
    offeredBy?: string;
    customerResponse?: PriceOfferCustomerResponse;
    attachment?: ImageModel;
    deadlineDate?: string;
}
