export const translation = {
    sellMyCar: 'Selg bilen min',
    nonBindingValuation:
        'Få en uforpliktende verdivurdering av våre bruktbil-eksperter.',
    valuationTradeIn: 'Få verdivurdering av innbyttebilen',
    valuationCompanyCar: 'Verdivurdering av firmabil',
    regNo: 'Registreringsnummer',
    mileage: 'Kilometerstand',
    mileageSuffix: 'km',
    zipCode: 'Postnummer',
    brand: 'Merke',
    vin: 'Chassisnummer',
    model: 'Modell',
    colour: 'Farge',
    email: 'E-postadresse',
    phoneNumber: 'Telefonnummer',
    phoneHelpText: 'Norsk telefonnummer, 8 siffer. Uten landkode',
    mileageHelpText: 'Rund av til nærmeste 1000.',
    firstName: 'Fornavn',
    lastName: 'Etternavn',
    carSection: 'Om bilen',
    customerSection: 'Din kontaktinformasjon',
    customerSectionInCompanyForm: 'Kundens kontaktinformasjon',
    customerSectionInTradeInForm: 'Kontaktperson og forhandler',
    comment: 'Noe mer du ønsker å informere om?',
    sendRequest: 'Send forespørsel',
    send: 'Send',
    termsInfoPart1: 'Les om ',
    termsInfoPart2: 'hvorfor vi samler inn personlig data under verdivurdering',
    termsInfoPart3: ' av bilen din. Du kan også lese ',
    termsInfoPart4: 'Møller bil sin generelle personvernerklæring',
    termsLabel:
        'Ja, jeg godtar at en forhandler kan kontakte meg angående bilen min',
    requestConfirmed: 'Takk for forespørselen',
    requestSent: 'Skjema er sendt!',
    requestConfirmedInfo:
        'For å få en enda mer korrekt verdivurdering, kan du allerede nå logge inn og laste opp bilder av bilen som gir oss enda bedre grunnlag for en verdivurdering.',
    requestConfirmedInfoNoLogin:
        'Len deg tilbake og du vil bli kontaktet av våre bruktbil-eksperter for en verdivurdering.',
    requestFailedError: 'Ops, noe gikk galt! Vennligst prøv igjen senere.',
    newCarTitle: 'Trenger du ny bil?',
    newCarInfo:
        'Bruktbil.no tilbyr flere tusen bruktbiler fra mange ulike bilmerker via forhandlere over hele Norge.',
    goToBruktbil: 'Gå til Bruktbil.no',
    mollerAddress: 'Frysjaveien 31, 0884 Oslo',
    generalErrorMessage:
        'Noe gikk galt. Vennligst prøv igjen eller kontakt oss på mail til <a href="mailto:{{email}}">{{email}}</a> dersom problemet fortsetter.',
    invalidCarMessage:
        'Finner ingen bil knyttet til dette registreringsnummeret. Sjekk at det er skrevet riktig, 2 bokstaver og 5 siffer.',
    logout: 'Logg ut',
    login: 'Logg inn',
    city: 'Poststed',
    cancel: 'Avbryt',
    purchasePrice: 'Innkjøpspris',
    priceSuffix: 'kr',
    chooseDealer: 'Velg forhandler',
    dealer: 'Forhandler',
    preferredDealer: 'Har du en foretrukket forhandler?',
    isRegisteredOwner: 'Er du registrert eier av bilen?',
    isPrivatePerson: 'Hvem sender du inn skjema på vegne av?',
    privatePerson: 'Privatperson',
    company: 'Bedrift',
    myPageDescription:
        'Verdivurdering av bilen din blir utført av et team som er eksperter på bruktbilmarkedet. Velger du å la oss kjøpe bilen gjør vi prosessen enkel og stressfri. Vi fikser alt det praktiske fra annonsering til omregistrering, og vi overtar hele ansvaret.',
    myPageDescriptionDeclined:
        'Selv om det ikke ble noen handel denne gangen, håper vi å se deg igjen ved en senere anledning.',
    myPageTitleNew: 'Vi har mottatt din forespørsel',
    myPageTitleInProgress: 'Vi jobber med å gi deg en verdivurdering',
    myPageTitleAccepted: 'Vi ønsker gjerne å kjøpe bilen din',
    myPageTitleOffer: 'Vårt tilbud til deg',
    myPageTitleDeclined: 'Dessverre ble det ingen handel, lykke til videre!',
    myPageTitleContractSent: 'Kontrakten er klar for signering',
    myPageTitleContractExpired: 'Fristen for signering har gått ut...',
    myPageTitleContractSigned: 'Takk for handelen',
    otherInfo: 'Annen informasjon',
    contactTitle: 'Noe du lurer på?',
    contactDescription:
        'Ta kontakt med oss på <a href="mailto:verdivurdering@moller.no">verdivurdering@moller.no</a> så hjelper vi deg gjerne!',
    errorTitle: 'Ops, noe gikk galt!',
    errorUnauthorizedTitle: 'Du har ikke tilgang til å se denne forespørselen',
    errorUnauthorizedDescription:
        'Vennligst gå tilbake i nettleseren for å komme tilbake der du var.',
    myPageError:
        'Vi klarte dessverre ikke hente ut informasjon om din forespørsel akkurat nå. Vennligst prøv igjen senere.',
    orgNr: 'Org.nr. 984 599 730',
    needNewCar: 'Vil du ha hjelp til kjøp av ny bil?',
    connectToDealer:
        'Vi kan sette deg i kontakt med en av våre nybil- eller bruktbilselgere, alt ettersom hva du trenger.',
    purchaseHelpDesired: 'Ja, jeg ønsker å få hjelp til kjøp av ny bil',
    chooseDealerCompanyCar: 'Hvor vil du levere din firmabil?',
    addOptionsPart1:
        'Fyll ut hvilke av følgende tilbehør som følger med ved levering, evt. i tillegg til kjente skader eller mangler på bilen.',
    addOptionsPart2:
        'Sommer- og vinterhjul (dekk som ligger på dekkhotell må hentes)',
    addOptionsPart3: 'Minimum 2 sett med nøkler ',
    addOptionsPart4: 'Ladekabler',
    addOptionsPart5: 'Dokumenter som service- og instruksjonshefte',
    addOptionsPart6: 'Annet utstyr (takstativ, gulvmatter, dekkverktøy ol.)',
    addOptionsTextFieldLabel: 'Fyll ut tilbehør og evt. skader eller mangler',
    companyCarInfo:
        'Om du har bestilt en ny firmabil skal du levere din gamle til samme forhandler hvor du bestilte ny. Om du ikke skal ha ny firmabil kan du velge den forhandleren som passer deg best.',
    onDeliveryRememberCompanyCarHeader: 'Husk følgende ved levering',
    onDeliveryRememberCompanyCarBullet1:
        'Bilen skal være normalt rengjort innvendig og utvendig',
    onDeliveryRememberCompanyCarBullet2:
        'Bilen skal leveres med minimum 25 % Strøm eller drivstoff igjen',
    onDeliveryRememberCompanyCarBullet3:
        'Husk å ta med deg bombrikke over til ny bil',
    stepTitleCustomer: 'Sjekk at informasjonen vi har om deg er riktig',
    stepTitleCar: 'Se gjennom din innsendte egenerklæring',
    stepTitlePayment: 'Hvilken konto vil du ha pengene på?',
    stepTitleSigning: 'Signer kontrakten',
    dealerComment: 'Kommentar fra forhandler',
    contractCustomerLabel: 'Din kontaktinformasjon',
    contractCarLabel: 'Om bilen',
    contractPaymentLabel: 'Utbetaling',
    contractSigningLabel: 'Signering',
    signingStepInfo:
        'Åpne kontrakten, les gjennom og signer nederst i dokumentet med BankID.',
    signingPrivacyInfo:
        'For å gjøre signeringen så enkel som mulig, brukes tredjepartsløsningen Scrive. Vil du vite mer om hvordan dine personopplysninger blir håndtert, kan du lese dette på siden <a href="https://verdivurdering.moller.no/personvern" target="_blank">“Hvorfor samler vi inn personopplysninger?”</a>',
    openContract: 'Åpne kontrakt',
    next: 'Neste',
    previous: 'Forrige',
    yes: 'Ja',
    no: 'Nei',
    hasTwoKeys: 'Selges bilen med to nøkler?',
    hasTwoTireSets: 'Selges bilen med to sett med hjul?',
    isTrimmed:
        'Er bilen chippet/trimmet eller avviker fra orginalen på annen måte?',
    hasBeenDamaged: 'Har bilen tidligere vært skadet?',
    damageInfo: 'Kan du beskrive skaden?',
    chargingCablesIncluded: 'Hvor mange ladekabler følger med bilen?',
    hasKnownFaults: 'Har bilen kjente feil eller mangler?',
    knownFaultsInfo: 'Hvilke feil eller mangler kjenner du til?',
    oneCable: '1 kabel',
    twoOrMoreCables: '2 eller flere kabler',
    noCables: 'Ingen',
    accountNumber: 'Ditt kontonummer',
    carPurchasePrice: 'Bilens innkjøpspris',
    carDebts: 'Pant/heftelser',
    paidPrice: 'Utbetales til deg',
    paymentInfo:
        'Når bilen er levert til oss, vil kjøpesummen utbetales innen 2 virkedager.',
    signContract: 'Signer kontrakt',
    contractFormErrorHeader: 'Følgende feil i skjema må fikses:',
    goToStep: 'Gå til',
    valuation: 'Verdivurdering',
    handoverDate: 'Overtakelsesdato',
    noValuationsTitle: 'Du har ingen forespørsler',
    noValuationsDescription:
        'Gå til vårt <a href="/">skjema for verdivurdering</a> for å sende inn forespørsel.',
    myPageOverviewTitle: 'Dine biler',
    myPageOverviewDescription:
        'Du har sendt inn flere biler til verdivurdering. Velg den bilen du ønsker å se status på.',
    contractSentDescription:
        'Vi har gleden av å tilby deg {{purchasePrice}} kr for din {{car}} med registreringsnummer {{regNo}}.',
    contractSignedDescription:
        'Takk for handelen! Sted for levering av bilen avtales med innkjøper.',
    buyingPrice: 'Kjøpesum',
    customerPaymentAmount: 'Utbetales til deg',
    startSigning: 'Start signering',
    finishSigning: 'Fullfør signering',
    finishSigningInfo:
        'Kontrakten din ligger klar! Fullfør signering for å gjennomføre salget.',
    none: 'Ingen',
    requiredField: 'Vennligst fyll ut dette feltet',
    errorPageTitle: 'Obs, det er rusk i maskineriet!',
    errorPageDescription:
        'Vi jobber med saken, så prøv igjen om en liten stund.',
    pageNotFoundTitle: '404 - Siden finnes ikke',
    pageNotFoundDescription:
        'For å finne det du leter etter kan du prøve å gå tilbake i nettleseren.',
    privacyStatement: 'Personvernerklæring',
    pickDeliveryDate: 'Velg ønsket leveringsdato',
    downloadContract: 'Last ned kontrakten',
    desiredDeliveryDate: 'Ønsket leveringsdato',
    address: 'Gate og husnummer',
    saveData: 'Lagre opplysninger',
    contractSignedInfo:
        'Kontrakten er signert. Ønsker du å laste ned kontrakten kan du gjøre det på forsiden.',
    goToFrontpage: 'Gå til forsiden',
    toFrontpage: 'Til forsiden',
    backToOverview: 'Til dine biler',
    declinedContractTitle: 'Avvist kontrakt',
    declinedContractMessage:
        'Du avviste kontrakten og salget blir ikke gjennomført.',
    validAccountNumber: 'Gyldig kontonummer',
    selfDeclaration: 'Egenerklæring',
    saveAndSend: 'Lagre og send',
    extraEquipmentInfo:
        'Hvis du har utstyr som f.eks. ekstra nøkler eller hjulsett skal dette vises frem ved en eventuell taksering.',
    selfDeclarationCardTitle: 'Fyll ut egenerklæringen',
    selfDeclarationCardInfo:
        'Egenerklæringen gir oss mer info om bilen din og et bedre grunnlag for verdivurdering.',
    seeSelfDeclaration: 'Se egenerklæringen',
    selfDeclarationSuccess: 'Egenerklæringen er sendt til forhandler.',
    selfDeclarationQuestionsInfo:
        'Ønsker du å gjøre endringer eller har spørsmål om egenerklæringen ta kontakt med forhandler.',
    rejectionTitle: 'Vi kan dessverre ikke kjøpe bilen din',
    rejectionReasonAge: 'er eldre enn 15 år',
    rejectionReasonMileage: 'har gått lengre enn 200 000 km',
    rejectionDescription:
        'Det er for tiden mange som ønsker å selge bilen sin til oss. Derfor har vi ikke mulighet til å hjelpe deg denne gangen, siden bilen {{reason}}.',
    rejectedModelDescription:
        'Vi håndterer ikke Tesla Model X og Model S og kan dessverre ikke hjelpe deg denne gangen.',
    valuationFormCarLabel: 'Hvilken bil vil du selge?',
    valuationFormExistingCarLabel: 'Hvor langt har bilen kjørt?',
    valuationFormSelfDeclarationLabel: 'Fyll ut egenerklæring om bilen',
    valuationFormPhoneNumberLabel: 'Bekreft mobilnummeret ditt',
    valuationFormOneTimeCodeLabel: 'Skriv inn engangskode',
    valuationFormCustomerLabel: 'Fyll inn din kontaktinformasjon',
    phoneNumberInfo:
        'Skriv inn mobilnummeret ditt og du vil få en tekstmelding med engangskode.',
    sendOneTimeCode: 'Send engangskode',
    sendOneTimeCodeAgain: 'Send meg engangskode på nytt',
    oneTimeCode: 'Engangskode',
    oneTimeCodeHelper: 'Engangskode du mottok på tekstmelding. 4 sifre.',
    oneTimeCodeInfo:
        'Vi har sendt engangskode på tekstmelding til +47 {{phoneNumber}}.',
    consent: 'Samtykke',
    requestOneTimeCodeGeneralError:
        'Noe gikk galt ved sending av engangskode. Vennligst prøv igjen eller kontakt oss på <a href="mailto:verdivurdering@moller.no">verdivurdering@moller.no</a> dersom problemet fortsetter.',
    verifyOneTimeCodeGeneralError:
        'Noe gikk galt ved verifisering av engangskode. Vennligst prøv igjen eller kontakt oss på <a href="mailto:verdivurdering@moller.no">verdivurdering@moller.no</a> dersom problemet fortsetter.',
    incorrectCodeError: 'Engangskoden du oppga er feil. Vennligst prøv igjen.',
    hasReachedRetryLimitError:
        'Du har oppgitt feil engangskode maks antall ganger. Du må sende engangskode på nytt for å prøve igjen.',
    hasReachedFrequencyLimitError:
        'Du må vente 1 minutt før du kan få tilsendt en ny engangskode.',
    priceEstimate: 'Prisestimat',
    weThinkWeCanOffer: 'Vi tror vi kan tilby deg',
    fromToPrice: '{{minPrice}} - {{maxPrice}} kr',
    priceEstimateInfoText1:
        'Vi undersøker, reparerer, vasker, polerer og klargjør bilen slik at neste eier blir fornøyd. Tilbudet vårt er derfor lavere enn den estimerte markedsprisen, men da slipper du å gjøre all denne jobben selv, og 2 års ansvar hvis det oppdages feil i etterkant.',
    priceEstimateInfoText2:
        'For å kunne gi en mer nøyaktig pris, må vi vite mer om bilen din.',
    priceEstimateCardInfo:
        'Dette spennet er basert på markedsprisen på lignende biler, minus kostnader for å gjøre bilen klar til salg.',
    Interested: 'Gi meg et konkret tilbud',
    NotInterested: 'Nei takk',
    Declined: 'Nei takk',
    Accepted: 'Godta tilbud',
    RenewOffer: 'Be om et nytt tilbud',
    interestedModalTitle: 'Takk for interessen!',
    notInterestedModalTitle:
        'Det var synd, men du er alltid velkommen tilbake!',
    interestedModalInfo:
        'Vi vil kontakte deg for å få mer info om bilen slik at vi kan gi deg en mer nøyaktig pris.',
    notInterestedModalInfo:
        'Selv om det ikke ble noen handel denne gangen, er det bare å ta kontakt igjen hvis du ombestemmer deg.',
    back: 'Tilbake',
    declinedInfo:
        'Skulle det fortsatt være av interesse kan du kontakte oss på <a href="mailto:{{email}}">{{email}}</a>',
    acceptedInfo: 'Takk for svar! Du vil snart bli kontaktet av en innkjøper.',
    addImagesCardTitle: 'Legg til bilder',
    uploadImages: 'Last opp bilder',
    uploadMoreImages: 'Last opp flere bilder',
    editImages: 'Rediger bilder',
    imagesOfCar: 'Bilder av bilen',
    imagesCardDescription:
        'Ta bilder av bilen utvendig og innvendig. Dette vil hjelpe oss med å gi en nøyaktig vurdering av bilen du ønsker å selge.',
    removeImage: 'Fjern bilde',
    imageGuidelines:
        'Ta bildene slik at bilen vises fra alle sider utvendig, deretter innvendig av interiør og dashboard.',
    save: 'Lagre',
    successfullyUploadedImages: 'Bildene er lagret.',
    uploadImagesError:
        'Noe gikk galt ved lagring av bilder. Vennligst prøv igjen eller kontakt oss på mail til <a href="mailto:{{email}}">{{email}}</a> dersom problemet fortsetter.',
    saved: 'Lagret',
    fetchImagesError:
        'Noe gikk galt ved henting av lagrede bilder. Vennligst prøv igjen eller kontakt oss på mail til <a href="mailto:{{email}}">{{email}}</a> dersom problemet vedvarer.',
    tradeInInfo:
        'Vårt sentrale innkjøpsteam kan hjelpe deg med prising av innbyttebilen. Husk å fylle ut informasjon om bilen og eier i CarWeb.',
    tradeInComment: 'Hvilket utstyr har bilen og når ønsker du svar?',
    attachments: 'Vedlegg',
    readMore: 'Les mer',
    readLess: 'Les mindre',
    deadlineInfo: 'Fristen for signering er ut dagen den {{date}}.',
    deadlineExpiredInfo:
        'Fristen for å signere har gått ut. Ta kontakt på mail til <a href="mailto:{{email}}">{{email}}</a> hvis du har spørsmål.',
    weCanOffer: 'Vi kan tilby deg',
    stepIndex: 'Steg {{index}}',
    priceOfferTitle: 'Motta pristilbud',
    carStepInfo:
        'Saken din er nå videresendt til en forhandler og du vil motta et pristilbud så snart det er klart.',
    priceStepDescription:
        'Vi er i gang med å behandle informasjonen vi har om bilen din, og vil gi deg et tilbud så fort som mulig.',
    contractStepDescription:
        'Hvis du godtar det endelige tilbudet, gjenstår bare signeringen. Deretter overleveres bilen til forhandler  og pengene blir utbetalt til deg.',
    contractSigned: 'Kontrakten er signert!',
    marketPrice: 'Markedspris',
    similarCarsSoldFor: 'Tilsvarende biler blir solgt for',
    marketPriceInfo:
        'Prisen på en bruktbil påvirkes av bilens alder, tilstand, utstyr, kilometerstand og andre faktorer. Basert på informasjonen du har gitt oss og lignende biler vi har solgt i den siste perioden, tror vi at din bil kan bli solgt for denne prisen etter at våre fagfolk har gjort den klar for salg.',
    receiveOfferInfo:
        'For å kunne gi et konkret tilbud for akkurat din bil trenger våre bruktbileksperter litt mer informasjon fra deg.',
    confirmedPriceEstimateInfo:
        'Du kan allerede nå logge inn og se et automatisk prisestimat av hva vi tror vi kan tilby for bilen din, med det lille vi vet per nå.',
    seePriceEstimate: 'Se ditt prisestimat',
    declinationTitle: 'Det var synd å høre...',
    declinationDescription:
        'For at vi skal kunne bli bedre ønsker vi å vite grunnen til at estimatet ikke var interessant for deg.',
    declinationQuestion: 'Hvorfor takker du nei?',
    sendReason: 'Send inn',
    PriceTooLow: 'Prisen var ikke som forventet',
    ValueExploration: 'Ville bare vite hva bilen er verdt',
    SellingToSomeoneElse: 'Ønsker å selge til noen andre',
    Other: 'Annet',
    explainWhy: 'Gjerne utdyp hvorfor',
    notLikely: 'Lite sannsynlig',
    veryLikely: 'Veldig sannsynlig',
    close: 'Lukk',
    npsQuestionContractSigned:
        'Nå som kontrakten er signert, hvor sannsynlig er det at du vil anbefale denne tjenesten til en venn eller kollega?',
    npsQuestionPriceEstimate:
        'Nå som du har fått et automatisk prisestimat, hvor sannsynlig er det at du vil anbefale denne tjenesten til en venn eller kollega?',
    npsQuestionOfferDeclined:
        'Nå som du har fått et tilbud, hvor sannsynlig er det at du vil anbefale denne tjenesten til en venn eller kollega?',
    thankYou: 'Takk for tilbakemelding!',
    yourFeedback: 'Din tilbakemelding',
    noPersonalInformation:
        'Vennligst ikke send inn personlige opplysninger som registreringsnummer, navn, telefonnummer, e-post osv.',
    weWishYourFeedback:
        'Vi ønsker gjerne tilbakemelding på hva vi kan gjøre bedre',
    sendFeedback: 'Gi tilbakemelding',
    skip: 'Hopp over',
    svvDownAlertHeading:
        'Vi kan for øyeblikket ikke vise informasjon om bilen din',
    svvDownAlertMessage:
        'Vi har midlertidige problemer med å hente data fra Vegvesenet, så du kan oppleve manglende informasjon om bilen din. Vi jobber med å løse dette, men du kan fortsatt bruke tjenesten som vanlig i mellomtiden.',
    unknown: 'Ukjent',
    offerExpiredMessage:
        'Fristen har gått ut, men du kan fremdeles be om et nytt tilbud. <br/> Vil vi kontakte deg og eventuelt justere tilbudet.',
    renewOfferInfo:
        'Du vil snart bli kontaktet av en innkjøper med et oppdatert tilbud på din bil.',
    offerValidDeadline: 'Tilbudet er gyldig ut dagen den {{date}}.',
    addExpectationsTitle: 'Vis oss tilsvarende bil',
    expectationsTitle: 'Se tilsvarende bil',
    expectationsDescription:
        'Del en lenke til en Finn-annonse, og beskriv enkelt forskjellene fra din bil, så kommer vi raskere frem til en riktigere pris.',
    similarCarsTitle: 'Sammenligning av tilsvarende bil på finn.no',
    yourSimilarCar: 'Din tilsvarende bil',
    similarCarUrlLabel: 'Finn-lenke',
    expectationsCommentLabel:
        'Hva er forskjellene på din bil og valgt bil på finn.no?',
    expectationsCommentTitle: 'Forskjellene på din bil og valgt bil',
    similarCarsFinnLink: 'Lignende biler til salgs på finn.no',
    aboutFinnPricesTitle: 'Priser på Finn.no',
    aboutFinnPricesBody:
        'Prisen du ser på en annonse på Finn.no er ikke nødvendigvis endelig salgspris. Bilene kan ligge lenge ute for salg og endelig pris er noe selger og kjøper blir enige om seg imellom.',
    aboutMarketPriceTitle: 'Markedspris',
    aboutMarketPriceBody:
        'Prisen på en bruktbil påvirkes av bilens alder, tilstand, utstyr, kilometerstand og andre faktorer. Basert på informasjonen du gir oss og lignende biler vi har solgt i den siste perioden, kan våre fagfolk komme frem til riktig pris.',
    expectationsSuccessMessage: 'Takk for innsending av tilsvarende bil!',
    PriceEstimateCardInfoDeviation:
        'På grunn av endret kilometerstand er prisestimatet annerledes enn i Bilhold.',
    offerDeclinationModalDescription:
        'Fortell oss gjerne hvorfor du takket nei, så vi kan forbedre tjenesten.',
    offerDeclinationModalQuestion: 'Hvorfor takket du nei til tilbudet?',
    DeclinedPriceTooLow: 'Prisen var ikke som forventet',
    DeclinedOther: 'Annet',
    DeclinedSellingToSomeoneElse: 'Ønsker å selge til noen andre',
    DeclinedValueExploration: 'Ville bare vite hva bilen er verdt',
    declinationHelperText:
        'Vennligst ikke send inn sensitive personopplysninger.',
    declinationOfferParked:
        'Din sak parkeres og tilbakemeldingen deles med forhandler.',
    declinationFooter: 'Du er alltid velkommen tilbake!',
};
