import { styled } from 'styled-components';
import { InfoCard, IconButton, Description } from '@moller/design-system';
import i18next from 'i18next';
import { tokens } from '@moller/gnist-themes/tokens.css.js';

const Card = styled(InfoCard)`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: var(--moller-spacing-xs) var(--moller-spacing-s);
    background-color: transparent;
    border-width: 1px;
    height: 40px;
    margin-top: var(--moller-spacing-xxs);
`;

interface ExternalLinkCardProps {
    readonly url: string;
    readonly label: string;
}

export const ExternalLinkCard = ({ url, label }: ExternalLinkCardProps) => {
    return (
        <Card>
            <Description style={{ fontSize: tokens.typeface.size.base }}>
                {i18next.t(label)}
            </Description>
            <IconButton
                icon="external_page"
                as="a"
                href={url}
                target="_blank"
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                label={i18next.t(label)}
            />
        </Card>
    );
};
