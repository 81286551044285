import {
    ContractFormModel,
    VALID_ACCOUNT_NUMBER,
} from '../utils/contractFormUtils';
import { breakpointDesktop, formInputs } from '@moller/design-system';
import i18next from 'i18next';
import { InputLayout } from '../../valuation-request-view/components/CompanyCarForm';
import { css, styled } from 'styled-components';
import { numberFormat } from '@/utils/numberFormat';
import { ContractModel } from 'types/ContractModel';
import { Divider } from '@/components/Divider';
import { DealerComment, DealerCommentHeading } from './CarStep';

interface PaymentStepProps {
    readonly contract: ContractModel;
    readonly accountNumber: string;
}

const { TextField } = formInputs<ContractFormModel>();

const PaymentInfo = styled.dl`
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: var(--moller-spacing-l) 0 var(--moller-spacing-s);
    width: 100%;

    @media screen and (min-width: ${breakpointDesktop}) {
        width: calc(50% - var(--moller-spacing-xs));
    }
`;

const PaymentInfoItem = styled.div<{ $bold?: boolean }>`
    ${(props) => css`
        display: flex;
        justify-content: space-between;
        font-weight: ${props.$bold ? 500 : 400};
        padding-bottom: var(--moller-spacing-base);
    `}
`;

export const PaymentStep = ({ contract, accountNumber }: PaymentStepProps) => {
    const sumDebts = contract.debts
        .map((debt) => Number(debt.amount))
        .filter((amount) => Number(amount))
        .reduce((accumulator, currentValue) => accumulator + currentValue, 0);

    const validAccountNumber = VALID_ACCOUNT_NUMBER.test(accountNumber);

    return (
        <>
            <InputLayout>
                <TextField
                    field="accountNumber"
                    label={i18next.t('accountNumber')}
                    inputMode="numeric"
                    validity={
                        validAccountNumber
                            ? {
                                  type: 'success',
                                  message: i18next.t('validAccountNumber'),
                              }
                            : undefined
                    }
                />
                <span className="hidden-mobile" />
                {contract.customerPaymentAmount && (
                    <PaymentInfo>
                        <PaymentInfoItem>
                            <dt>{i18next.t('carPurchasePrice')}</dt>
                            <dd>{`${numberFormat(
                                contract.purchasePrice,
                            )} kr`}</dd>
                        </PaymentInfoItem>
                        <PaymentInfoItem>
                            <dt>{i18next.t('carDebts')}</dt>
                            <dd>{`${numberFormat(sumDebts)} kr`}</dd>
                        </PaymentInfoItem>
                        <PaymentInfoItem $bold>
                            <dt>{i18next.t('paidPrice')}</dt>
                            <dd>{`${numberFormat(
                                contract.customerPaymentAmount,
                            )} kr`}</dd>
                        </PaymentInfoItem>
                    </PaymentInfo>
                )}
                <p>{i18next.t('paymentInfo')}</p>
            </InputLayout>
            {contract.comment && (
                <>
                    <Divider />
                    <DealerCommentHeading>
                        {i18next.t('dealerComment')}
                    </DealerCommentHeading>
                    <DealerComment>{contract.comment}</DealerComment>
                </>
            )}
        </>
    );
};
