import {
    noValidation,
    required,
    ValidatorMap,
} from '@moller/design-system/utilities/validation';
import { SelfDeclarationModel } from '@/types/SelfDeclarationModel';

export interface SelfDeclarationFormModel {
    hasTwoKeys: boolean | undefined;
    hasTwoTireSets: boolean | undefined;
    isTrimmed: boolean | undefined;
    hasBeenDamaged: boolean | undefined;
    damageInfo: string;
    hasKnownFaults: boolean | undefined;
    knownFaultsInfo: string;
}

export interface SelfDeclarationElectricCarFormModel
    extends SelfDeclarationFormModel {
    chargingCablesIncluded: string | undefined;
}

export const initialBooleanValue = (value: boolean | undefined) =>
    value !== undefined ? value : undefined;

export const initialSelfDeclarationFormValues = (
    selfDeclaration: SelfDeclarationModel | undefined,
) => {
    return {
        hasTwoKeys: initialBooleanValue(selfDeclaration?.hasTwoKeys),
        hasTwoTireSets: initialBooleanValue(selfDeclaration?.hasTwoTireSets),
        isTrimmed: initialBooleanValue(selfDeclaration?.isTrimmed),
        hasBeenDamaged: initialBooleanValue(selfDeclaration?.hasBeenDamaged),
        damageInfo: selfDeclaration?.damageInfo || '',
        hasKnownFaults: initialBooleanValue(selfDeclaration?.hasKnownFaults),
        knownFaultsInfo: selfDeclaration?.knownFaultsInfo || '',
    };
};

export const initialSelfDeclarationElectricCarFormValues = (
    selfDeclaration: SelfDeclarationModel | undefined,
) => {
    return {
        ...initialSelfDeclarationFormValues(selfDeclaration),
        chargingCablesIncluded:
            selfDeclaration?.chargingCablesIncluded || undefined,
    };
};

export const selfDeclarationFormValidator: ValidatorMap<SelfDeclarationFormModel> =
    {
        hasTwoKeys: required(),
        hasTwoTireSets: required(),
        isTrimmed: required(),
        hasBeenDamaged: required(),
        damageInfo: noValidation(),
        hasKnownFaults: required(),
        knownFaultsInfo: noValidation(),
    };

export const selfDeclarationElectricCarFormValidator: ValidatorMap<SelfDeclarationElectricCarFormModel> =
    {
        ...selfDeclarationFormValidator,
        chargingCablesIncluded: required(),
    };
