import { PROACTIVE_LEAD, VALUATIONS } from '@/http/endpoints';
import { post } from '@/http/post';
import { DealerModel } from '@/types/DealerModel';
import { ValuationType } from '@/utils/enums/ValuationType';
import { RejectionType } from '@/utils/enums/RejectionType';
import { put } from '@/http/put';
import format from 'string-format';
import { RequestModel } from '../queries/useConfirmRequest';
import { SelfDeclarationModel } from '@/types/SelfDeclarationModel';
import { CustomerRole } from '@/utils/enums/CustomerRole';

interface ConfirmRequestModel {
    registrationNumber: string;
    mileage: string;
    zipcode: string;
    email: string;
    phonenumber: string;
    firstName: string;
    lastName: string;
    comment: string;
    selectedDealer: DealerModel | null;
    desiredDeliveryDate?: string;
    purchaseHelpDesired: boolean;
    valuationType: ValuationType;
    authToken?: string;
    uuid?: string;
    referralSrc?: string;
    selfDeclaration?: SelfDeclarationModel;
    customerRole?: CustomerRole;
}

interface ConfirmRequestResponse {
    customerLoginAvailable: boolean;
    priceEstimateAvailable: boolean;
    rejected: boolean;
    rejectionReason: { type: RejectionType };
}

export const confirmRequest = (
    requestForm: RequestModel,
    selectedDealer: DealerModel | undefined,
    valuationType: ValuationType,
    authToken?: string,
    uuid?: string,
    referralSrc?: string,
) => {
    const payload: ConfirmRequestModel = {
        registrationNumber: requestForm.registrationNumber.trim(),
        mileage: requestForm.mileage.trim(),
        zipcode: requestForm.zipCode || '',
        email: requestForm.email,
        phonenumber: requestForm.phoneNumber || '',
        firstName: requestForm.firstName || '',
        lastName: requestForm.lastName || '',
        comment: requestForm.comment || '',
        selectedDealer: selectedDealer || null,
        desiredDeliveryDate:
            requestForm.desiredDeliveryDate &&
            new Date(requestForm.desiredDeliveryDate).getDate()
                ? new Date(requestForm.desiredDeliveryDate).toISOString()
                : undefined,
        purchaseHelpDesired: requestForm.purchaseHelpDesired || false,
        valuationType: valuationType,
        authToken: authToken || undefined,
        referralSrc: referralSrc || undefined,
        selfDeclaration: requestForm.selfDeclaration,
        customerRole: requestForm.customerRole || undefined,
    };

    if (valuationType === ValuationType.PROACTIVE) {
        return put<ConfirmRequestResponse, ConfirmRequestModel>(
            format(PROACTIVE_LEAD, uuid || ''),
            payload,
        ).request;
    }
    return post<ConfirmRequestResponse, ConfirmRequestModel>(
        VALUATIONS,
        payload,
    ).request;
};
