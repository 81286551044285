import { IVariant, useFlag, useVariant } from '@unleash/proxy-client-react';

type AlertMessageType = {
    message: string;
    type: 'info' | 'success' | 'warning' | 'error';
    heading?: string | undefined;
};

type MaintenancePageType = {
    header: string;
    message: string;
};

export const useFeatureFlags = () => {
    const showDeadlineDate: boolean = useFlag('bruktbilx2-offer-deadline-date');
    const selectDealerOptional: boolean = useFlag(
        'bruktbilx2-select-dealer-optional'
    );
    const showSelectDealer: boolean = useFlag(
        'bruktbilx2-show-preferred-dealer-select'
    );
    const alertMessage: Partial<IVariant> = useVariant(
        'bruktbilx2-alert-message'
    );
    const responseAlertMessage: Partial<IVariant> = useVariant(
        'bruktbilx2-response-alert-message'
    );

    const maintenancePage: Partial<IVariant> = useVariant(
        'bruktbilx2-maintenance-verdivurdering-page'
    );

    const showDeclinationModal: boolean = useFlag(
        'bruktbilx2-show-offer-declination-modal'
    );

    return {
        showDeclinationModal: showDeclinationModal,
        showDeadlineDate: showDeadlineDate,
        selectDealerOptional: selectDealerOptional,
        showSelectDealer: showSelectDealer,
        alertMessage:
            alertMessage.enabled && alertMessage.payload?.value
                ? (JSON.parse(alertMessage.payload.value) as AlertMessageType)
                : undefined,
        responseAlertMessage:
            responseAlertMessage.enabled && responseAlertMessage.payload?.value
                ? (JSON.parse(
                      responseAlertMessage.payload.value
                  ) as AlertMessageType)
                : undefined,
        maintenancePage:
            maintenancePage.enabled && maintenancePage.payload?.value
                ? (JSON.parse(
                      maintenancePage.payload.value
                  ) as MaintenancePageType)
                : undefined,
    };
};
