import { useMutation, useQueryClient } from '@tanstack/react-query';
import { MY_VALUATION_OFFER } from '@/http/endpoints';
import { put } from '@/http/put';
import format from 'string-format';
import { PriceOfferCustomerResponse } from '@/types/OfferModel';

type PriceOfferPayload = {
    customerResponse: PriceOfferCustomerResponse;
    customerResponseComment?: string;
}

export const useUpdatePriceOffer = (
    valuationId: string | undefined,
    offerId: string,
    onSuccess?: () => void
) => {
    const updatePriceEstimate = (
        payload: PriceOfferPayload
    ) =>
        put<{ customerResponse: PriceOfferPayload }, unknown>(
            format(MY_VALUATION_OFFER, valuationId || '', offerId),
            payload
        ).request;
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (payload: PriceOfferPayload) =>
            updatePriceEstimate(payload),
        onSuccess: () => {
            void queryClient.invalidateQueries({
                queryKey: ['myPageValuation', valuationId],
            }),
                onSuccess && onSuccess();
        },
    });
};
