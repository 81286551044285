import { css, styled } from 'styled-components';
import { IconButton } from '@moller/design-system';
import { PropsWithChildren, useEffect, useState } from 'react';
import i18next from 'i18next';

const Wrapper = styled.div<{
    $isCollapsible: boolean;
    $isCollapsed: boolean;
    $contentHeight: string;
    $collapsedHeight: string;
}>`
    ${(props) => css`
        position: relative;
        max-height: ${props.$isCollapsed
            ? props.$collapsedHeight
            : props.$contentHeight}px;
        overflow: hidden;
        height: auto;
        transition: max-height 300ms ease;
        ${props.$isCollapsible &&
        !props.$isCollapsed &&
        'margin-bottom: var(--moller-spacing-s);'}
    `}
`;

const Gradient = styled.div<{ $backgroundColorVariable: string }>`
    ${(props) => css`
        height: var(--moller-size-xxl);
        width: 100%;
        position: absolute;
        bottom: 0;
        background: linear-gradient(
            0deg,
            var(${props.$backgroundColorVariable}) 0%,
            transparent 150%
        );
    `}
`;

export const Collapse = ({
    children,
    id,
    className,
    backgroundColorVariable,
    collapsedHeight,
}: PropsWithChildren<{
    id: string;
    className?: string;
    backgroundColorVariable: string;
    collapsedHeight: string;
}>) => {
    const [isCollapsed, setIsCollapsed] = useState(true);
    const [isCollapsible, setContentIsCollapsible] = useState(false);
    const [contentHeight, setContentHeight] = useState('');

    useEffect(() => {
        const elementHeight = document.getElementById(id)?.offsetHeight;
        if (elementHeight && elementHeight > Number(collapsedHeight)) {
            setContentIsCollapsible(true);
            setContentHeight(elementHeight.toString());
        }
    }, [collapsedHeight, id]);

    return (
        <div className={className}>
            <Wrapper
                $isCollapsible={isCollapsible}
                $isCollapsed={isCollapsed}
                $contentHeight={contentHeight || ''}
                $collapsedHeight={collapsedHeight}
            >
                <div id={id}>{children}</div>
                {isCollapsible && isCollapsed && (
                    <Gradient
                        $backgroundColorVariable={backgroundColorVariable}
                    />
                )}
            </Wrapper>
            {isCollapsible && (
                <IconButton
                    icon={isCollapsed ? 'expand_more' : 'expand_less'}
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                    label={i18next.t(isCollapsed ? 'readMore' : 'readLess')}
                    onClick={() => setIsCollapsed(!isCollapsed)}
                    showLabel="left"
                />
            )}
        </div>
    );
};
