import { LoadingOverlay } from '@/components/loading/LoadingOverlay';
import { PriceOfferCustomerResponse } from '@/types/OfferModel';
import { usePurchaserEmail } from '@/utils/usePurchaserEmail';
import { useUpdatePriceOffer } from '@/views/my-page/queries/useUpdatePriceOffer';
import {
    ActionText,
    AlertBanner,
    Modal,
    RadioButton,
    TextArea,
} from '@moller/design-system';
import i18next from 'i18next';
import { useState } from 'react';
import { useParams } from 'react-router';
import { styled } from 'styled-components';
import parse from 'html-react-parser';

const Title = styled(ActionText)`
    font-size: var(--moller-typeface-size-l);
    font-weight: 500;
`;

const RadioButtonWrapper = styled.fieldset`
    border: 0;
    padding: 0;
    margin: 0;
    display: grid;
    row-gap: var(--moller-spacing-xs);
`;

const Description = styled.p`
    margin: var(--moller-spacing-xxs) 0 var(--moller-spacing-l);
`;

const Footer = styled.p`
    margin: var(--moller-spacing-xxs) 0 var(--moller-spacing-l);
    text-align: center;
    color: var(--moller-color-primary);
    font-weight: var(--moller-typeface-weight-medium);
`;

const Legend = styled.legend`
    margin-bottom: var(--moller-spacing-xs);
    font-weight: 500;
`;

const ErrorBanner = styled(AlertBanner)`
    margin-top: var(--moller-spacing-m);

    a {
        color: var(--moller-color-on-error-container);
    }
`;

const ModalWrapper = styled(Modal)`
    width: 496px;
    padding-bottom: var(--moller-spacing-l);
`;

const CommentWrapper = styled.div`
    margin-top: var(--moller-spacing-s);

    > * {
        width: 100%;
    }
`;

const declinationResponses: PriceOfferCustomerResponse[] = [
    PriceOfferCustomerResponse.DECLINED_PRICE_TOO_LOW,
    PriceOfferCustomerResponse.DECLINED_VALUE_EXPLORATION,
    PriceOfferCustomerResponse.DECLINED_SELLING_TO_SOMEONE_ELSE,
    PriceOfferCustomerResponse.DECLINED_OTHER,
];

interface DeclinationOfferResponseModalProps {
    readonly offerId: string;
    readonly close: () => void;
    readonly showNPSBanner: () => void;
    readonly isModalOpen: boolean;
}

export const DeclinationOfferResponseModal = ({
    close,
    showNPSBanner,
    isModalOpen,
    offerId,
}: DeclinationOfferResponseModalProps) => {
    const { id } = useParams<{ id: string }>();
    const { email } = usePurchaserEmail();
    const updateOffer = useUpdatePriceOffer(id, offerId, () => {
        close();
        showNPSBanner();
    });
    const { isError, isLoading } = updateOffer;
    const [selectedResponse, setSelectedResponse] = useState(
        PriceOfferCustomerResponse.DECLINED_PRICE_TOO_LOW,
    );

    const [responseComment, setResponseComment] = useState('');

    return (
        <ModalWrapper
            handleClose={close}
            isOpen={isModalOpen}
            primaryAction={{
                onClick: () =>
                    updateOffer.mutate({
                        customerResponse: selectedResponse,
                        customerResponseComment:
                            responseComment.trim() || undefined,
                    }),
                label: i18next.t('sendReason'),
            }}
            secondaryAction={{
                onClick: close,
                label: i18next.t('cancel'),
            }}
            size="large"
        >
            {isLoading && <LoadingOverlay delayMillis={0} />}
            <Title>{i18next.t('declinationTitle')}</Title>
            <Description>
                {i18next.t('offerDeclinationModalDescription')}
            </Description>
            <Legend>{i18next.t('offerDeclinationModalQuestion')}</Legend>
            <RadioButtonWrapper>
                {declinationResponses.map((response) => (
                    <div key={response}>
                        <RadioButton
                            key={response}
                            label={i18next.t(response)}
                            id={response}
                            name="rejectionReasonRadio"
                            value={response === selectedResponse}
                            onChange={() => setSelectedResponse(response)}
                        />
                    </div>
                ))}
            </RadioButtonWrapper>
            <CommentWrapper>
                <TextArea
                    id="responseComment"
                    label={i18next.t('explainWhy')}
                    value={responseComment}
                    onChange={(e) => setResponseComment(e.target.value)}
                    maxLength={200}
                    helperText={i18next.t('declinationHelperText')}
                />
            </CommentWrapper>
            <Description>{i18next.t('declinationOfferParked')}</Description>
            <Footer>{i18next.t('declinationFooter')}</Footer>
            {isError && (
                <ErrorBanner
                    message={
                        parse(
                            i18next.t('generalErrorMessage', {
                                email: email,
                            }),
                        ) as string
                    }
                    type="error"
                    density="compact"
                />
            )}
        </ModalWrapper>
    );
};
